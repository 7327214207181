h6.customPrimary {
  color: #fff;
  margin: 0 auto;
  text-align: center;
}

.logo {
  max-width: 300px;
  margin: 0 auto;
}

.customInput {
  background-color: white;
}

.customTitle {
  font-size: 16px !important;
  color: #4f4f4f;
}

.customButton {
  background-color: pink;
}

.menuButton {
  margin-right: 20;
  margin-left: -12;
}

.toolbarButtons {
  margin: 0 auto;
  color: white;
}

.indicatorGreen {
  background-color: rgba(0, 128, 0, 0.2)
}

.indicatorOrange {
  background-color: orange
}

.indicatorRed {
  background-color: red
}